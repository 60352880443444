































































import { ShortRecord } from "@/store/models";
import { Component, Vue, Prop } from "vue-property-decorator";
import { ReportType } from "./ReportHelper";

@Component
export default class ReportCoverPageComponent extends Vue {
  @Prop() private reportHelper: any;
  @Prop() private reportData: any;

  private ReportType = ReportType;

  private getFilterLabel(filter: ShortRecord[] | ShortRecord) {
    if (Array.isArray(filter)) {
      return filter.length > 0 ? filter.map((item) => item.displayName).join(", ") : "All";
    } else {
      return filter.displayName;
    }
  }
}
