




















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class ReportNoticeComponent extends Vue {
  @Prop() private reportData: any;
}
