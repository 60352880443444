










import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class CCABReportNotice extends Vue {
  @Prop() private reportData: any;
}
